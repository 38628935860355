var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-manager-container" },
    [
      _c(
        "div",
        { staticClass: "button-block" },
        [
          _vm.hasPermission(_vm.$permissionFunctionCode.ADD)
            ? _c(
                "el-button",
                {
                  attrs: { round: "", plain: "", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addRowData(false)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "", plain: "" },
              on: {
                click: function ($event) {
                  return _vm.refreshDataMixin(_vm.getTableData)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("refresh")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-block" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                "default-expand-all": "",
                height: "100%",
                "default-sort": { prop: "seq" },
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("base-no-data", { attrs: { slot: "empty" }, slot: "empty" }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: _vm.$t("menuName"),
                  width: "250",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t(scope.row.name)))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sort",
                  align: "center",
                  label: _vm.$t("sort"),
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("type"), align: "center", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type: scope.row.type === 0 ? "warning" : "",
                              size: "mini",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  `${
                                    scope.row.type === 0
                                      ? _vm.$t("catalog")
                                      : _vm.$t("menu")
                                  }`
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "router",
                  align: "center",
                  label: _vm.$t("routePath"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "component",
                  align: "center",
                  label: _vm.$t("componentPath"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "icon",
                  align: "center",
                  label: _vm.$t("menuIcon"),
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("em", {
                          class: scope.row.icon,
                          staticStyle: { "font-size": "18px" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "enable",
                  align: "center",
                  label: _vm.$t("enable"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            width: 35,
                            disabled: _vm.getDisabledStatus(scope.row),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onSwitchChange($event, scope.row)
                            },
                          },
                          model: {
                            value: scope.row.enable,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "enable", $$v)
                            },
                            expression: "scope.row.enable",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: _vm.$t("operate") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.hasPermission(_vm.$permissionFunctionCode.ADD)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addRowData(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]
                            )
                          : _vm._e(),
                        _vm.hasPermission(_vm.$permissionFunctionCode.EDIT)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editRowData(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]
                            )
                          : _vm._e(),
                        _vm.hasPermission(_vm.$permissionFunctionCode.DELETE)
                          ? _c("base-button-delete", {
                              on: {
                                confirm: function ($event) {
                                  return _vm.deleteRowDataMixin(
                                    scope.row,
                                    _vm.getTableData
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.addDialogVisible
        ? _c("base-form-add-or-edit", {
            attrs: {
              visible: _vm.addDialogVisible,
              "url-save": this.saveUrl,
              "url-update": this.saveUrl,
              fields: _vm.fields,
            },
            on: {
              closed: function ($event) {
                _vm.addDialogVisible = false
              },
              "submit-success": _vm.getTableData,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }